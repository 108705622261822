export const BASE_URL = "https://gateway.africaspocket.com/";
// export const BASE_URL = "http://127.0.0.1/";

export const RESOURCES = {
  coreFunctions: `api:IaYS-M9j`,
  auth: `api:fH8MNGPq:v1`,
  auth1: `api:fH8MNGPq`,
  smileId: `api:-ZfrvVeY`,
  billing: `api:g7w7gqaI`,
  google: `api:fbxDwhKy`,
  multiAsset: `api:RiDzwQIz`,
  africasTalking: `api:Ql0QXoET`,
  notifications: `api:TzoP0tpe:v1`,
};
export const MY_NOTIFICATIONS_URL = `${RESOURCES.notifications}/my-notifications`;
export const COMPOUND_INTEREST_CALCULATOR_URL = `${RESOURCES.auth1}/calc/compound-interest`;
export const PMT_CALCULATOR_URL = `${RESOURCES.auth1}/calc/pmt`;
export const GET_PLAN_URL = `${RESOURCES.coreFunctions}/investment/load_goals`;
export const CHECKLIST_URL = `${RESOURCES.auth}/auth/checklist_0`;
export const LOAD_GOALS_URL = `${RESOURCES.auth}/auth/load_goals_1`;
export const LOAD_DASHBOARD_GOALS_URL = `${RESOURCES.auth}/auth/load_goals`;
export const LOGIN_URL = `${RESOURCES.auth}/auth/login`;
export const SIGNUP_URL = `${RESOURCES.auth}/auth/signup`;
export const GOOGLE_SIGNUP_URL = `${RESOURCES.google}/oauth/google/init`;
export const GOOGLE_SIGNUP_CONTINUE_URL = `${RESOURCES.google}/oauth/google/continue`;
export const USER_DETAILS_URL = `${RESOURCES.auth}/auth/me`;
export const VERIFY_EMAIL_CODE_URL = `${RESOURCES.auth}/auth/verify-email-with-code`;
export const AUTH_ROUTE_URL = `${RESOURCES.auth}/auth/route`;
export const RESEND_PASSWORD_RESET_EMAIL_URL = `${RESOURCES.auth}/auth/resend_backup`;
export const RESEND_VERIFICATION_EMAIL_URL = `${RESOURCES.auth}/auth/resend`;
export const FORGOT_PASSWORD_URL = `${RESOURCES.auth}/auth/forgot_password`;
export const RESET_PASSWORD_VALIDATE_URL = `${RESOURCES.auth}/auth/reset_password_validate`;
export const RESET_PASSWORD_URL = `${RESOURCES.auth}/auth/reset-password`;
export const CHECK_ACCOUNT_STATUS_URL = `${RESOURCES.auth}/auth/account-status`;
export const GET_RECEIPT_URL = `${RESOURCES.billing}/receipt`;
export const PAY_MPESA_URL = `${RESOURCES.billing}/pay/mpesa`;
export const PAY_CARD_URL = `${RESOURCES.billing}/pay/card`;
export const GET_USER_TRANSACTION_URL = `${RESOURCES.billing}/user/transaction`;
export const KYC_URL = `${RESOURCES.smileId}/kyc/enhanced_check_id_only`;
export const CHECK_AUTH_STATUS_URL = `${RESOURCES.auth}/auth/me`;
export const GET_TOKEN_URL = `${RESOURCES.auth}/auth/token`;
export const LOAD_DATA_URL = `${RESOURCES.auth}/auth/load`;
export const SUBMIT_INVESTMENT_DETAILS_URL = `${RESOURCES.coreFunctions}/goal_calculator/add_0`;
export const MAKE_A_TRANSACTION_URL = `${RESOURCES.billing}/transaction/catchup/add`;
export const CREATE_A_FINANACIAL_FREEDOM_GOAL= `${RESOURCES.coreFunctions}/create_financial_freedom_goal`;
export const FINANCIAL_FREEDOM_CALCULATOR_URL = `${RESOURCES.coreFunctions}/calculator/financial-freedom`;
export const FINANCIAL_FREEDOM_NUMBER_UPDATE_URL = `${RESOURCES.coreFunctions}/financial_freedom/update_allocation`;
export const FINANCIAL_FREEDOM_CONFIG_URL = `${RESOURCES.coreFunctions}/financial_freedom/config`;
export const FINANCIAL_FREEDOM_MILESTONE_URL = `${RESOURCES.coreFunctions}/financial_freedom/milestones`;
export const ONBOARDING_FINANCIAL_FREEDOM_URL = `${RESOURCES.coreFunctions}/financial_freedom`;
export const FINANCIAL_FREEDOM_RESULT_URL = `${RESOURCES.coreFunctions}/financial_freedom_results`;
export const GOAL_CALCULATOR_URL = `${RESOURCES.coreFunctions}/goal_calculator`;
export const GOAL_TYPES_URL = `${RESOURCES.coreFunctions}/goal_types`;
export const GOAL_GET_SUGGESTED_MONTHLY_SAVINGS_URL = `${RESOURCES.coreFunctions}/get_suggested_monthly_savings`;
export const GOAL_CALCULATE_ACHIEVE_DATE_URL = `${RESOURCES.coreFunctions}/calculate_goal_achieve_date`;
export const GOAL_CALCULATOR_UPDATE_URL = `${RESOURCES.coreFunctions}/goal_calculator/update`;
export const EMERGENCY_FUND_URL = `${RESOURCES.coreFunctions}/emergency_fund`;
export const EMERGENCY_FUND_UPDATE_URL = `${RESOURCES.coreFunctions}/emergency_fund/update`;
export const UPDATE_FINANCE_URL = `${RESOURCES.coreFunctions}/update_finance`;
export const FINANCIAL_FREEDOM_UPDATE_URL = `${RESOURCES.coreFunctions}/financial_freedom/update`;
export const WISHLIST_NPER_URL = `${RESOURCES.coreFunctions}/wishlist/nper`;
export const WISHLIST_ADD_URL = `${RESOURCES.coreFunctions}/wishlist/add`;
export const DELETE_GOAL_URL = `${RESOURCES.coreFunctions}/delete/goal`;
export const GOAL_GROUP_URL = `${RESOURCES.multiAsset}/my_goals`;
export const GOAL_GET_URL = `${RESOURCES.coreFunctions}/goal_calculator/get`;
export const ACCOUNT_SETTINGS_USER_DATA_URL = `${RESOURCES.auth}/user/get_data`;
export const ACCOUNT_SETTINGS_RESET_PASSWORD_URL = `${RESOURCES.auth.slice(
  0,
  -3,
)}/user/reset_password`;
export const ACCOUNT_SETTINGS_UPLOAD_PROFILE_URL = `${RESOURCES.auth}/user/upload_profile`;
export const KYC_LOAD_DATA_URL = `${RESOURCES.auth}/kyc/load_data`;
export const KYC_SEND_OTP_URL = `${RESOURCES.africasTalking}/send_otp`;
export const KYC_VERIFY_OTP_URL = `${RESOURCES.africasTalking}/verify_otp`;
export const KYC_UPLOAD_KRA_URL = `${RESOURCES.auth}/kyc/kra`;
export const KYC_UPLOAD_BANK_URL = `${RESOURCES.auth}/kyc/bank`;
export const KYC_UPLOAD_ID_URL = `${RESOURCES.auth}/kyc/ID_Passport`;
export const KYC_UPLOAD_PHONE_URL = `${RESOURCES.auth}/kyc/Photo`;
export const KYC_UPDATE_NEXT_OF_KIN_URL = `${RESOURCES.auth}/kyc/next_of_kin`;
export const GET_USER_SUBSCRIPTION_URL = `${RESOURCES.billing}/subscription`;
export const LOAD_WITHDRAWAL_URL = `${RESOURCES.coreFunctions}/withdrawal/load`;
export const CALCULATE_WITHDRAWAL_URL = `${RESOURCES.coreFunctions}/withdrawal/calculate`;
export const REQUEST_WITHDRAWAL_URL = `${RESOURCES.coreFunctions}/withdrawal/request`;
export const ADD_PHONE_NUMBER_URL = `${RESOURCES.coreFunctions}/registration/add-phone-number`;
export const CONTACT_US_URL = `${RESOURCES.auth}/auth/contact-us`;
export const UPDATE_USER_STAGE_URL = `${RESOURCES.coreFunctions}/update_stage`;
